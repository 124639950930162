// @flow

import * as React from 'react'
import styled from 'styled-components'
import { RobotoMedium } from './Typography/Fonts'
import { Colors } from './Colors'
import HeartSvg from './Icons/HeartSvg'

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  right: 0;
  left: 0;
  bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${RobotoMedium.fontFamily};
  font-size: 12px;
  font-weight: ${RobotoMedium.weight};
  line-height: 14px;
  color: ${process.env.REACT_APP_LIGHT_UI === 'true'
    ? Colors.black30
    : Colors.white30};

  svg {
    margin: 0 4px;
  }
`

export default (): React.Node => {
  return (
    <Wrapper>
      Made with <HeartSvg /> by Custhome
    </Wrapper>
  )
}
