// @flow

import * as React from 'react'
import { Colors } from '../Colors'

export default (): React.Element<'svg'> => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill={Colors.brand} fillRule="nonzero">
      <path
        d="M7.147 2.272c-.056.056-.185.056-.241 0C5.35.722 3.445.145 1.416 1.492a.583.583 0 0 0-.068.053C.157 2.62-.202 4.027.1 5.605c.21 1.092.7 2.153 1.358 3.189l.025.036c1.748 2.342 3.337 3.667 4.699 4.283a2.17 2.17 0 0 0 1.592.01c1.386-.626 2.975-1.951 4.723-4.293l.025-.036c.655-1.032 1.149-2.094 1.366-3.185.314-1.575-.02-2.98-1.177-4.058a.583.583 0 0 0-.064-.052c-2.026-1.412-3.897-.825-5.5.773z"
        opacity=".1"
      />
      <path d="M7.148 2.272c-.056.056-.185.056-.241 0C5.35.722 3.446.145 1.417 1.492a.583.583 0 0 0-.068.053C.158 2.62-.201 4.027.102 5.605c.21 1.092.7 2.153 1.358 3.189l.025.036c1.748 2.342 3.337 3.667 4.699 4.283a2.17 2.17 0 0 0 1.592.01c1.386-.626 2.975-1.951 4.723-4.293l.025-.036c.655-1.032 1.149-2.094 1.366-3.185.314-1.575-.02-2.98-1.177-4.058a.583.583 0 0 0-.064-.052c-2.026-1.412-3.897-.825-5.5.773zm4.8.16.035.035c1.199 1.178 1.074 2.837.135 4.691l-.104.2c-.107.2-.223.402-.348.606l-.114.185.012-.017c-.208.28-.414.543-.616.79l-.2.243C9.448 10.71 8.293 11.61 7.318 12.05a.983.983 0 0 1-.679-.01l-.074-.035c-1.113-.527-2.486-1.68-4.042-3.734l-.09-.12-.052-.083a13.38 13.38 0 0 1-.125-.205l-.12-.203C.96 5.6.714 3.757 2.065 2.473l.035-.033-.037.024c1.47-.976 2.813-.57 4.022.634.511.51 1.376.51 1.888 0 1.23-1.225 2.508-1.641 3.932-.695l.043.03z" />
    </g>
  </svg>
)
